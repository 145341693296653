import React from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { useAdminAuth } from '../../contexts/AdminAuthContext';

type AdminProtectedRouteProps = {
  children: JSX.Element;
  redirectPath?: string;
};

function AdminProtectedRoute({
  children,
  redirectPath = '/admin/login'
}: AdminProtectedRouteProps) {
  const { isAuthenticated, isLoading } = useAdminAuth();
  const location = useLocation();

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return children;
}

export default AdminProtectedRoute;